// extracted by mini-css-extract-plugin
export var accountProfile = "styles-module--accountProfile--XSk2w";
export var avatar = "styles-module--avatar--gCzkr";
export var avatarArea = "styles-module--avatarArea--I5wSv";
export var avatarImage = "styles-module--avatarImage--Us+W5";
export var avatarUrl = "styles-module--avatarUrl--mpkzB";
export var btnRegisterIcon = "styles-module--btnRegister-icon--ZhABx";
export var helloTitle = "styles-module--helloTitle--hAQeQ";
export var label = "styles-module--label--D3vCU";
export var mLeft = "styles-module--mLeft--5S9b0";
export var mRight = "styles-module--mRight--XShAm";
export var mVouchers = "styles-module--mVouchers--0whVo";
export var membershipInfo = "styles-module--membershipInfo--4FObV";
export var membershipName = "styles-module--membershipName--MAAPp";
export var membershipTitle = "styles-module--membershipTitle--wu5e1";
export var nonMembership = "styles-module--nonMembership--93LPG";
export var uploadFile = "styles-module--uploadFile--d0QR6";
export var vIcon = "styles-module--vIcon--udxlA";
export var vTitle = "styles-module--vTitle--ePD1W";
export var voucherItem = "styles-module--voucherItem--Mg9VD";
export var walletCoin = "styles-module--walletCoin--NWC4G";