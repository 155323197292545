import React, {useEffect, useState} from 'react';
import {Form, Input, DatePicker, Select, Button, message} from 'antd';
import moment from 'moment';
import avatar from '~images/avatar.png';
import Camera from '~components/icons/camera';
import ChevronRight from '~components/icons/chevron-right';
import Loading from '~components/page-loader';
import {get, uploadFile, put} from '~utils/api';
import {buildImageUrl, toWalletCoin} from '~utils/helper';
import {profileUpdateNotifications, rulesUpdateInfoUserForm, GenderOptions, defaultGender} from '~constants/consumer';
import Membership from '~components/icons/membership';
import X from '~components/icons/x';
import DefaultAvatar from '~components/icons/avatar-default.svg';

import * as styles from './styles.module.css';

const {Option} = Select;

const VoucherItem = ({title, background, onClick}) => (
  <div className={styles.voucherItem} style={{backgroundColor: background}}>
    <div className={styles.vTitle}>{title}</div>
    <div className={styles.vIcon} onClick={onClick}>
      <X fill='white' size='12' />
    </div>
  </div>
);

const AccountProfile = () => {
  const [form] = Form.useForm();
  const [customerInfo, setCustomerInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [avatarURL, setAvatarURL] = useState(undefined);
  const [avatarFile, setAvatarFile] = useState(undefined);
  const [isCloseFirstVoucher, setIsCloseFirstVoucher] = useState(false);
  const [isCloseSecondVoucher, setIsCloseSecondVoucher] = useState(false);
  const [isCoinLow, setIsCoinLow] = useState(false);

  const getCurrentUserInfo = async () => {
    try {
      setLoading(true);
      const res = await get(`/v1/customer/customer-profile`);
      let membership =
        !res.membership || new Date(res.membership?.expiredDate) < new Date()
          ? undefined
          : {
              id: res.membership.membershipPackageId,
              isFreeFirstOrder: res.membership.isFreeFirstOrder,
              isUsedFreeFirstOrder: res.membership.isUsedFreeFirstOrder,
              percentDiscount: res.membership.percentDiscount,
            };
      if (membership?.id) {
        const membershipPackage = await get(`/v1/consumer-membership-package/${membership.id}`);
        membership = {
          ...membership,
          color: membershipPackage.color,
          name: membershipPackage.name,
          type: membershipPackage.type,
          maxOriginalPrice: membershipPackage.maxOriginalPrice,
        };
      }

      const walletCoin = (res?.availableBalance ?? 0) + (res?.membershipAvailableBalance ?? 0);
      setCustomerInfo({
        ...res,
        membership,
        walletCoin,
      });
      setIsCoinLow(walletCoin < 100000);
      form.setFieldsValue({
        fullname: res.name,
        birthDate: res.birthDate ? moment(res.birthDate) : '',
        gender: res.gender || defaultGender,
        mobileNo: res.mobileNo || '',
        email: res.email,
      });
      if (res.avatar) {
        setAvatarURL(res.avatar);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCurrentUserInfo();
  }, []);

  const handleFile = e => {
    setAvatarFile(e.target.files[0]);
  };

  const getAvatar = () => {
    if (avatarURL && !avatarFile) return buildImageUrl(avatarURL);
    if (avatarFile) return URL.createObjectURL(avatarFile);
    return avatar;
  };

  const onFinish = async values => {
    try {
      setLoading(true);

      const request = {
        name: values.fullname,
        gender: values.gender,
        birthDate: moment(values.birthDate).format('YYYY-MM-DDTHH:mm:ssZ'),
        email: values.email,
        mobileNo: values.mobileNo,
        avatar: avatarURL || null,
      };

      if (avatarFile) {
        const presignedUrl = await get(`/v1/customer/upload/presignedUrl?file_name=${avatarFile.name}`);
        const uploadForm = new FormData();

        uploadForm.append('file', avatarFile);

        const res = await uploadFile(presignedUrl.url, uploadForm);
        if (res) {
          // eslint-disable-next-line prefer-destructuring
          request.avatar = res[0];
        }
      }

      const res = await put('/v1/customer/customer-profile', request);
      if (res !== undefined) {
        await getCurrentUserInfo();
        message.success(profileUpdateNotifications.success);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    } finally {
      setLoading(false);
      setAvatarFile(undefined);
    }
  };

  return (
    <div className={styles.accountProfile}>
      <Loading loading={loading} />
      <div className={styles.updateForm}>
        <div className={styles.avatarArea}>
          <div className={styles.avatar}>
            <div className={styles.avatarImage}>
              {avatarFile || avatarURL ? (
                <div
                  className={styles.avatarUrl}
                  style={{
                    backgroundImage: `url(${getAvatar()})`,
                  }}
                />
              ) : (
                <img src={DefaultAvatar} width={50} height={42} alt='consumer-avatar' />
              )}
            </div>
            <div className={styles.uploadFile}>
              <label onChange={handleFile} htmlFor='upload-img'>
                <span>
                  <Camera />
                </span>
                <input type='file' hidden id='upload-img' accept='image/*' />
              </label>
            </div>
          </div>
          <div className={styles.membershipInfo}>
            <div className={styles.mLeft}>
              <div className={styles.label}>Gói thành viên</div>
              {customerInfo?.membership ? (
                <div className={styles.membershipTitle} style={{color: customerInfo.membership.color}}>
                  <Membership fill={customerInfo.membership.color} />
                  <div className={styles.membershipName}>{customerInfo.membership.name}</div>
                </div>
              ) : (
                <div className={styles.nonMembership}>Chưa có</div>
              )}
            </div>
            <div className={styles.mRight}>
              <div className={styles.label}>Rentzy Xu</div>
              <div className={styles.walletCoin} style={{color: isCoinLow ? 'red' : '#8996BD'}}>
                {toWalletCoin(customerInfo?.walletCoin ?? 0)}
              </div>
            </div>
          </div>
        </div>
        {customerInfo && <div className={styles.helloTitle}>Hello, {customerInfo.name}</div>}
        {customerInfo?.membership && !isCoinLow && (
          <div className={styles.mVouchers}>
            {customerInfo.membership.isFreeFirstOrder &&
              !customerInfo.membership.isUsedFreeFirstOrder &&
              !isCloseFirstVoucher && (
                <VoucherItem
                  title='Bạn có 1 voucher thuê đồ miễn phí'
                  background='#8DAFEF'
                  onClick={() => setIsCloseFirstVoucher(true)}
                />
              )}
            {customerInfo.membership.percentDiscount && !isCloseSecondVoucher && (
              <VoucherItem
                title={`Thuê đồ chỉ bằng ${customerInfo.membership.percentDiscount}% giá Sản phẩm`}
                background='#8996BD'
                onClick={() => setIsCloseSecondVoucher(true)}
              />
            )}
          </div>
        )}
        <div className={styles.main}>
          <Form layout='vertical' onFinish={onFinish} form={form} autoComplete='off'>
            <Form.Item label='Họ và tên' name='fullname' rules={rulesUpdateInfoUserForm.fullname}>
              <Input className='form-item' autoComplete={false} placeholder='Nhập họ và tên' />
            </Form.Item>
            <Form.Item label='Ngày sinh' name='birthDate' rules={rulesUpdateInfoUserForm.birthDate}>
              <DatePicker
                style={{width: '100%'}}
                placeholder='Nhập ngày sinh'
                format='YYYY/MM/DD'
                disabledDate={current => {
                  const customDate = moment().format('YYYY-MM-DD');
                  return current && current > moment(customDate, 'YYYY-MM-DD');
                }}
              />
            </Form.Item>
            <Form.Item label='Giới tính' name='gender' rules={rulesUpdateInfoUserForm.gender}>
              <Select placeholder='Chọn giới tính' style={{width: '100%'}}>
                {GenderOptions.map(item => (
                  <Option value={item.key}>{item.name}</Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label='Số điện thoại' name='mobileNo' rules={rulesUpdateInfoUserForm.mobileNo}>
              <Input placeholder='Nhập số điện thoại' type='text' />
            </Form.Item>
            <Form.Item label='Email' name='email' rules={rulesUpdateInfoUserForm.email}>
              <Input placeholder='Nhập email' />
            </Form.Item>
            <Form.Item>
              <Button size='large' className='btn-submit btn-register' type='primary' htmlType='submit'>
                Cập nhật
                <span className='btn-register-icon'>
                  <ChevronRight fill='white' />
                </span>
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default AccountProfile;
