import React from 'react';

const Camera = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='36' height='36' fill='none' viewBox='0 0 36 36'>
      <circle cx='18' cy='18' r='18' fill='#000' />
      <g stroke='#fff' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' clipPath='url(#clip0_1340_36460)'>
        <path d='M26.614 23.478a1.565 1.565 0 01-1.565 1.565H10.962a1.565 1.565 0 01-1.566-1.565V14.87a1.565 1.565 0 011.566-1.565h3.13l1.565-2.348h4.696l1.565 2.348h3.13a1.565 1.565 0 011.566 1.566v8.608z' />
        <path d='M18.005 21.913a3.13 3.13 0 100-6.261 3.13 3.13 0 000 6.261z' />
      </g>
      <defs>
        <clipPath id='clip0_1340_36460'>
          <path fill='#fff' d='M0 0H18.783V18.783H0z' transform='translate(8.613 8.609)' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Camera;
